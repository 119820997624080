<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton v-if="this.params.data.locked === false" @click="onlockOrUnlockAccount" buttonText="" title="Verrouiller">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'lock']" />
            </template>
        </BaseButton>
        <BaseButton v-if="this.params.data.locked === true" @click="onlockOrUnlockAccount" buttonText="" title="Déverrouiller">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'unlock']" />
            </template>
        </BaseButton>
        <BaseButton @click="onEditAccount" buttonText="" title="Modifier">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'pen']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListAccountsActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onlockOrUnlockAccount() {
                this.params.context.componentParent.lockOrUnlockAccount(this.params.data);
            },
            onEditAccount() {
                this.params.context.componentParent.displayEditAccountModal(this.params.data);
            }
        }
    }        
</script>