<style scoped>
</style>

<template>
    <ButtonTopbar buttonText="Créer un utilisateur" @onTopbarButtonClicked="displayCreateAccountModal">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
    </ButtonTopbar>
    <Aggrid 
        apiRoute='account/list'
        :apiParams="filter"
        :columnDefs="columnDefs" 
        :frameworkComponents="frameworkComponents"
        :valueFormatterFunctions="valueFormatterFunctions"
        :context="context"
        :exportName="exportName"
        aggridHeightSubstract="100px"
    >
        <template v-slot:filter>
            <BaseCheckbox label="Afficher les comptes verrouillés" @onChange="onLockedFilterChange"/>
            <div>
                <BaseSelect :defaultOptions="accountFilter" @onChange="onFiltersChange($event)" label="Type de compte" name="accountType" v-model="accountType" :maxHeight="300"/>
            </div>
        </template>
    </Aggrid>
    <Modal v-show="displayModalCreateOrEditAccount === true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'user']" />
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrEditMode === 'create'">CRÉER UN UTILISATEUR</div>
            <div v-show="createOrEditMode === 'edit'">MODIFIER UN UTILISATEUR</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseInput 
                        v-model="account.username" 
                        label="Identifiant" 
                        type="text" 
                        name="username" 
                        validator="text" 
                        :error="formErrors?.username?.error?.message" 
                        @onChange="onFormInputChange" 
                        :displayError="displayError" 
                    />
                </div>
                <div>
                    <BaseSelect
                        v-model="account.establishment"
                        label="Etablissement"
                        :defaultOptions="establishments"
                        name="establishment"
                        :display-tag="true"
                    />
                    <BaseSelect
                        v-model="account.role"
                        label="Rôle"
                        :defaultOptions="roles"
                        name="role"
                        :error="formErrors?.role?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                        :required="true"
                        :maxHeight="300"
                    />
                </div>
                <div>
                    <BaseSelect
                        v-show="account.role?.value === 'customer'"
                        v-model="account.customer"
                        label="Client"
                        name="customer"
                        api="customer/search"
                        fieldValue="id" 
                        fieldLabel="name"
                        :error="formErrors?.customer?.error?.message" 
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                        :searchable="true"
                        :minChars="1"
                    />
                  <BaseSelect
                      v-show="account.role?.value !== 'customer' && account.role?.value !== undefined"
                      v-model="account.mailsType"
                      label="Envoi de mail si"
                      :defaultOptions="typeMail"
                      name="mailsType"
                      :error="formErrors?.role?.error?.message"
                      @onChange="onFormInputChange"
                      :displayError="displayError"
                      :multiple="true"
                      :allowEmpty="true"
                      :optionSelectAll="true"
                  />
                </div>
                <div>
                    <BaseInput 
                        v-model="account.lastname"
                        label="Nom"
                        type="text"
                        name="lastname"
                        validator="text"
                        :error="formErrors?.lastname?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                    <BaseInput 
                        v-model="account.firstname"
                        label="Prénom"
                        type="text"
                        name="firstname"
                        validator="text"
                        :error="formErrors?.firstname?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                </div>
                <div>
                    <BaseInput 
                        v-model="account.email"
                        label="Email"
                        type="text"
                        name="email"
                        validator="email"
                        :error="formErrors?.email?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                </div>
                <div>
                    <BaseInput 
                        v-model="account.phoneNumber" 
                        label="Téléphone" 
                        type="text" 
                        name="phoneNumber" 
                        validator="phone" 
                        :error="formErrors?.phoneNumber?.error?.message" 
                        @onChange="onFormInputChange" 
                        :displayError="displayError"
                        :required="false"
                    />
                    <BaseInput 
                        v-model="account.tagId"
                        label="Identifiant du badge"
                        class="uppercase"
                    />
                </div>
                <div>
                    <BaseInput
                        v-model="account.password"
                        label="Mot de passe"
                        type="password"
                        name="password1"
                        validator="password"
                        :error="formErrors?.password1?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                    <BaseInput 
                        v-model="passwordConfirm"
                        label="Confirmer le mot de passe"
                        type="password"
                        name="password2"
                        validator="text"
                        :error="formErrors?.password2?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError" 
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Dialog ref="dialog" />
</template>

<script>
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar'
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import BaseCheckbox from '../../components/Base/BaseCheckbox'
    import BaseSelect from '../../components/Base/BaseSelect'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import ListAccountsActionButtonsRenderer from './ListAccountsActionButtonsRenderer'
    import Modal from '../../components/App/Modal'
    import Dialog from '../../components/App/Dialog'
    import axios from 'axios'
    import changeTypeEnum from '../../enums/changeTypeEnum'
    import accountRoleEnum from "@/enums/accountRoleEnum";

    export default {
        name: 'Accounts',
        components: {
            ButtonTopbar,
            BaseButton,
            BaseInput,
            BaseCheckbox,
            BaseSelect,
            Aggrid,
            Modal,
            Dialog
        },
        data() {
            let that = this;
            return {
                accountFilter: [
                    { label: "Interne", value: "internal" },
                    { label: "Externe", value: "external" },
                ],
                accountType: null,
                columnDefs: [
                    { field: 'username', headerName : `Identifiant`, flex: 1, lockPosition: true },
                    {
                        field: 'roleText',
                        headerName : `Rôle`,
                        flex: 1,
                        lockPosition: true,
                        valueFormatterFunctionName: 'RoleValueFormatterFunction',
                        suppressMenu: true
                    },
                    { field: 'lastname', headerName : `Nom`, flex: 1, lockPosition: true },
                    { field: 'firstname', headerName : `Prénom`, flex: 1, lockPosition: true },
                    { field: 'customer.name', headerName : `Client associé`, flex: 1, lockPosition: true, suppressMenu: true },
                    { headerName : '', width: 130, cellRenderer: 'ListAccountsActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListAccountsActionButtonsRenderer: ListAccountsActionButtonsRenderer
                },
                valueFormatterFunctions: new Map([
                    [
                        'RoleValueFormatterFunction',
                        function(params){
                            return that.formateRole(params?.data?.role);
                        }
                    ]
                ]),

                context: null,
                displayModalCreateOrEditAccount: false,
                createOrEditMode: null,
                roles: [
                    { label: 'Client', value: 'customer' },
                    { label: 'Technicien', value: 'technician' },
                    { label: 'ADV', value: 'workshopManager' },
                    { label: 'Resp. Atelier', value: 'technicianManager' },
                    { label: 'Administrateur', value: 'admin' },
                ],
                typeMail: [],
                account: null,
                filter: {
                    locked: false
                },
                passwordConfirm: null,
                displayError: false,
                formErrors: {},
                exportName: "accounts",
                establishments: [
                    { label: "EBC", value: "ebc" },
                    { label: "SMG", value: "smg" }
                ],
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetAccount();
        },
        methods: {
            closeModal() {
                this.passwordConfirm = null;
                this.resetAccount();
                this.displayModalCreateOrEditAccount = false;
                this.displayError = false;
            },
            submitModal() {
                if (this.createOrEditMode === 'create') {
                    this.createAccount();
                } else if (this.createOrEditMode === 'edit') {
                    this.editAccount();
                }
            },
            loadMailType(){
              axios.get("mailType/list",{
                showPreloader: true
              }).then((response) => {
                this.typeMail = []
                this.typeMail = response.data.map( res => res = {label: res.nom, value: res.code, id: res.id, })
              }).catch(() => {})
            },
            displayCreateAccountModal() {
                this.createOrEditMode = 'create';
                this.resetAccount();
                this.loadMailType();
                this.displayModalCreateOrEditAccount = true;
            },
            displayEditAccountModal(account) {
                this.createOrEditMode = 'edit';
                this.loadMailType();
                this.account = JSON.parse(JSON.stringify(account));
                this.account.role = {
                    value: this.account.role,
                    label: this.formateRole(this.account.role)
                }
                this.account.password = null;
                if(this.account.customer !== null) {
                    this.account.customer = {
                        value: this.account.customer.id,
                        label: this.account.customer.name
                    }
                }

                this.account.mailsType = this.account.mailsType.map(element => ({id: element.id, label: element.nom, value: element.code}));

                this.displayModalCreateOrEditAccount = true;
            },
            resetAccount() {
                this.account = {
                    username: null,
                    password: null,
                    firstname: null,
                    lastname: null,
                    role: null,
                    email: null,
                    phoneNumber: null,
                    tagId: null,
                    customer: null,
                    establishment: null,
                    mailsType: null,
                };
                this.passwordConfirm = null;
            },
            formateAccount(account) {
                account = JSON.parse(JSON.stringify(account));
                account.role = account.role.value;
                account.customerId = account.customer?.value
                account.establishment = account.establishment?.value
                account.mailsType = account.mailsType?.map(res => res.id)
                delete account.customer;
                return account;
            },
            createAccount() {
                if (!this.checkForm()) return;

                axios
                .post('account/create', this.formateAccount(this.account), { 
                    toastSuccessMessage: `Utilisateur créé`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.emitter.emit('ag-grid-reload')
                    this.closeModal();
                })
                .catch(() => {});
            },
            editAccount() {
                if (!this.checkForm()) return;

                axios
                .put('account/update', this.formateAccount(this.account), { 
                    toastSuccessMessage: `Modification effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.emitter.emit('ag-grid-reload')
                    this.closeModal();
                })
                .catch(() => {});
            },
            lockOrUnlockAccount(account) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir ${account.locked ? 'déverouiller' : 'verrouiller'} cet utilisateur ?`
                }).then(() => {
                    axios
                    .put('account/changeLockedAccount/' + account.id, {
                        locked: !account.locked
                    }, { 
                        toastSuccessMessage: `Modification effectuée`, 
                        toastError: true, 
                        showPreloader: true 
                    })
                    .then(() => {
                        this.emitter.emit('ag-grid-reload')
                    });
                })
                .catch(() => {});
            },
            onFormInputChange(input) {

                if (input.error?.message !== null) {
                    this.formErrors[input.name] = input;
                } else {
                    delete this.formErrors[input.name];
                }

                // En édition, si les champs "Mot de passe" ne sont pas saisis, on n'affiche pas d'erreur
                let fieldPassword1 = this.formErrors?.password1?.value?.trim() || null;
                let fieldPassword2 = this.formErrors?.password2?.value?.trim() || null;

                if (this.createOrEditMode === 'edit') {
                    if ((fieldPassword1 == null || fieldPassword1.length === 0) && (fieldPassword2 == null || fieldPassword2.length === 0)) {
                        delete this.formErrors.password1;
                        delete this.formErrors.password2;
                    }
                }

                // Gestion des erreurs personalisées
                if (!this.formErrors.password1 || this.formErrors.password1.error?.type === 'custom') {
                    // Si les mots de passe sont différents
                    if (this.account.password != this.passwordConfirm) {
                        this.formErrors.password1 = {
                            name: 'password1',
                            value: this.account.password,
                            error: {
                                type: 'custom',
                                message: `Le mot de passe est différent de la confirmation`
                            }
                        }
                    } else {
                        delete this.formErrors.password1;
                    }
                }

                if (!this.formErrors.customer || this.formErrors.customer.error?.type === 'custom') {
                    // Si rôle de l'utilisateur est 'Client' mais qu'aucun client n'est renseigné
                    if (this.account.role?.value === 'customer' && this.account.customer === null) {
                        this.formErrors.customer = {
                            name: 'customer',
                            value: this.account.customer,
                            error: {
                                type: 'custom',
                                message: `Vous devez renseigner un client associé au compte.`
                            }
                        }
                    } else {
                        delete this.formErrors.customer;
                    }
                }
                
                if (input.changeType === changeTypeEnum.USER) {
                    // Si on vient de modifier le nom ou le prénom et que l'on a déjà les deux champs renseignés
                    if ((input.name === 'lastname' || input.name === 'firstname')
                    && (this.account.firstname != null && this.account.firstname.trim().length > 0)
                    && (this.account.lastname != null && this.account.lastname.trim().length > 0)) {
                        this.account.username = `${this.account.firstname[0].toLowerCase()}.${this.account.lastname.toLowerCase()}`;
                    }
                }
            },
            checkForm() {
                this.displayError = true;

                return Object.keys(this.formErrors).length === 0;
            },
            onLockedFilterChange(locked) {
                this.filter.locked = locked;
            },
            formateRole(role) {
                let roleStr = this.roles.filter((element) => element.value === role);
                if (roleStr.length > 0) {
                    return roleStr[0].label;
                } else {
                    return '';
                }
            },
            onFiltersChange(input) {
                if (input.changeType === changeTypeEnum.USER) {
                    const inputValue = input?.value?.value
                    if (inputValue === undefined) {
                        for (let filter of this.accountFilter) {
                            delete this.filter[filter.value]
                        }
                    }
                    else {
                        if (this.filter[inputValue] === undefined)
                            this.filter[inputValue] = true;
                        delete this.filter[(this.accountFilter.find(filter => filter.value !== inputValue)).value]
                    }
                }
            }
        }
    }
</script>